export default {
  color: `black`,
  textDecoration: `none`,
  userSelect: `none`,
  ':visited': {
    color: 'black'
  },
  ':hover': {
    color: 'alpha'
  }
}
