import common from './common'

export default {
  ...common.badge,
  bg: `alphaLighter`,
  color: `black`,
  ':hover': {
    color: `white`,
    bg: `black`
  }
}
