// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-core-src-templates-custom-js": () => import("./../../../../packages/blog/core/src/templates/custom.js" /* webpackChunkName: "component---packages-blog-core-src-templates-custom-js" */),
  "component---packages-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-core-src-templates-post-js" */),
  "component---packages-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-core-src-templates-posts-js" */),
  "component---packages-blog-core-src-templates-sales-js": () => import("./../../../../packages/blog/core/src/templates/sales.js" /* webpackChunkName: "component---packages-blog-core-src-templates-sales-js" */),
  "component---packages-blog-pages-src-pages-404-jsx": () => import("./../../../../packages/blog/pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-404-jsx" */),
  "component---packages-blog-pages-src-pages-auteurs-jsx": () => import("./../../../../packages/blog/pages/src/pages/auteurs.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-auteurs-jsx" */),
  "component---packages-blog-pages-src-pages-formulaire-de-contact-jsx": () => import("./../../../../packages/blog/pages/src/pages/formulaire-de-contact.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-formulaire-de-contact-jsx" */)
}

