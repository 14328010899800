import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Flex, NavLink, IconButton, Heading, Divider, Box } from 'theme-ui'
import { buildResponsiveVariant, hashCode } from '../utils'

const styles = {
  divider: {
    mt: 3
  },
  submenu: {
    zIndex: `200 !important`,
    background: `white`,
    position: `absolute`,
    width:`400px`
  },
  submenuItem: {
    py: `1rem`,
    borderBottom : `1px solid rgba(0,0,0,0.1)`,
    px: 4,
  },
  submenuItemLink: {
    textDecoration: `none`,
    fontWeight: `bold`,
    color: `rgba(0,0,0,0.7)`,
  },
}

const NavigationList = ({ navKey, wrapperProps, items, ...props }) =>
  items ? (
    <Flex {...wrapperProps}>
      {items.map((menuItem, index) => {
        return <NavigationItem key={`${navKey}-${index}`} {...menuItem} {...props} />
      })}
    </Flex>
  ) : null

const NavigationDivider = ({ index }) =>
  index !== 0 && <Divider sx={styles.divider} />

const NavigationItem = ({
  name,
  slug,
  url,
  Icon,
  color,
  variant,
  iconOnly, 
  submenu
}) => {
  let linkProps = {
    sx: { variant: iconOnly ? 'icon' : variant }
  }

  const [showSubmenu, setShowSubmenu] = useState(false)
 
  //External link
  if (url) {
    linkProps = {
      ...linkProps,
      as: 'a',
      href: url,
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  }
  //Internal link
  if (slug) {
    linkProps = {
      ...linkProps,
      as: Link,
      to: slug
    }
  }

  return iconOnly ? (
    <IconButton {...linkProps} title={name}>
      {Icon && <Icon color={color} />}
    </IconButton>
  ) : ( !submenu ? (
      <NavLink {...linkProps}>
        {Icon && <Icon color={color} />}
        {name}
      </NavLink>
    ) : (
      <Box onMouseLeave={() => setShowSubmenu(false)}>
        <NavLink {...linkProps} 
          onMouseEnter={() => setShowSubmenu(true)}
          
        >
          {name}
        </NavLink>
        {showSubmenu && <Box sx={styles.submenu} >
          {submenu.map(item => {
            return <Box sx={styles.submenuItem}><Link style={styles.submenuItemLink} to={item.slug}>{item.name}</Link></Box>
          })}
        </Box>}
      </Box>
    )
  )
}

const Navigation = ({
  items,
  variant,
  headingProps,
  wrapperStyle,
  ...props
}) => {
  if (!items || !items.length) return null

  const wrapperVariant = buildResponsiveVariant('lists.links', variant)
  const linkVariant = buildResponsiveVariant('links', variant)

  const navKey = `${hashCode(
    items.map(node => node.title || node.name).join()
  )}-nav`

  const wrapperProps = {
    sx: { variant: wrapperVariant, ...wrapperStyle },
    key: navKey
  }

  const hasGroupedItems = Array.isArray(items[0].items)

  return hasGroupedItems ? (
    items.map((node, i) => (
      <Fragment key={`nav-menu-${i}`}>
        <NavigationDivider index={i} />
        <Heading {...headingProps}>{node.title}</Heading>
        <NavigationList
          navKey={navKey}
          wrapperProps={wrapperProps}
          items={node.items}
          variant={linkVariant}
          {...props}
        />
      </Fragment>
    ))
  ) : (
    <NavigationList
      navKey={navKey}
      wrapperProps={wrapperProps}
      items={items}
      variant={linkVariant}
      {...props}
    />
  )
}

export default Navigation

Navigation.defaultProps = {
  variant: 'horizontal'
}

const itemsShape = PropTypes.shape({
  name: PropTypes.string,
  slug: PropTypes.string,
  color: PropTypes.string,
  Icon: PropTypes.element
})

const variantShape = PropTypes.oneOf(['horizontal', 'vertical'])

Navigation.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.arrayOf(variantShape), variantShape]),
  iconOnly: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  headingProps: PropTypes.object,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: itemsShape
      })
    ),
    PropTypes.arrayOf(itemsShape)
  ])
}
